const navbarScroll = () => {
    const navbar = document.querySelector(".navbar");
    const path = window.location.pathname;
    
    if (path.includes("photography")) {
        window.onscroll = () => {
            if (window.scrollY > 5) {
                navbar.classList.add("nav-scroll-photo");
            } else {
                navbar.classList.remove("nav-scroll-photo");
            }
        };
    } else if (path.includes("music")) {
        window.onscroll = () => {
            if (window.scrollY > 5) {
                navbar.classList.add("nav-scroll-music");
            } else {
                navbar.classList.remove("nav-scroll-music");
            }
        };
    } else {
        window.onscroll = () => {
            if (window.scrollY > 300) {
                navbar.classList.add("nav-scroll");
            } else {
                navbar.classList.remove("nav-scroll");
            }
        };
    }
}

export { navbarScroll } ;